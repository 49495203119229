<template>
  <div id="app">
    <!-- Conditional Rendering Based on Device Type -->
    <div v-if="isMobile">
      <!-- Mobile Template -->
      <div class="floating-search">
        <template v-if="isMapPage">
          <input type="text" class="search-input" placeholder="Search..." />
          <button class="search-btn">Search</button>
        </template>
      </div>

      <div class="home-page-content">
        <router-view />
      </div>

      <nav class="navbar">
        <div class="nav-left">
          <router-link class="nav-btn" to="/">Home</router-link>
          <router-link class="nav-btn" to="/map">Map</router-link>
          <router-link class="nav-btn" to="/shop">Shop</router-link>
        </div>

        <div class="nav-right">
          <div v-if="!isAuthenticated" class="auth-options">
            <router-link class="nav-btn login-btn" to="/login">Login</router-link>
          </div>

          <router-link v-if="isAuthenticated" class="user-profile" to="/user/profile">
            <img :src="profilePictureUrl || defaultProfilePictureUrl" alt="Profile Picture" class="profile-pic" />
          </router-link>

          <button v-if="isAuthenticated" @click="logout" class="nav-btn">Logout</button>
        </div>
      </nav>
    </div>
    <div v-else>
      <!-- Desktop Template -->
      <nav class="navbar">
        <div class="nav-left">
          <router-link class="nav-btn" to="/">ChinaGO</router-link>
          <router-link class="nav-btn" to="/map">Map</router-link>
          <router-link class="nav-btn" to="/shop">Shop</router-link>
        </div>

        <div class="nav-search">
          <input type="text" class="search-input" placeholder="Search..." />
          <button class="search-btn">Search</button>
        </div>

        <div class="nav-right">
          <div v-if="!isAuthenticated" class="auth-options">
            <router-link class="nav-btn login-btn" to="/login">Login</router-link>
          </div>

          <router-link v-if="isAuthenticated" class="user-profile" to="/user/profile">
            <img :src="profilePictureUrl || defaultProfilePictureUrl" alt="Profile Picture" class="profile-pic" />
          </router-link>

          <button v-if="isAuthenticated" @click="logout" class="nav-btn">Logout</button>
        </div>
      </nav>

      <div class="home-page-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAuthenticated: !!localStorage.getItem("authToken"), // Check if the user is authenticated
      profilePictureUrl: "", // Initialize profilePictureUrl
      defaultProfilePictureUrl: "", // Initialize defaultProfilePictureUrl
      isMobile: false // Initialize isMobile
    };
  },
  computed: {
    isMapPage() {
      return this.$route.path === '/map';
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("authToken"); // Clear the authentication token
      localStorage.removeItem("profilePictureUrl"); // Clear the profile picture URL
      this.isAuthenticated = false; // Update the state
      this.$router.push("/"); // Redirect to the home page
    },
    loadProfilePicture() {
      const storedProfilePictureUrl = localStorage.getItem("profilePictureUrl");
      this.profilePictureUrl = storedProfilePictureUrl
        ? `${this.getBaseUrl()}${storedProfilePictureUrl}`
        : this.defaultProfilePictureUrl;
    },
    getBaseUrl() {
      const protocol = process.env.NODE_ENV === "production" ? "https:" : "http:";
      const hostname = window.location.hostname;
      const port = process.env.NODE_ENV === "production" ? "" : "8000";
      return `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    },
    detectMobile() {
      this.isMobile = window.innerWidth <= 768;
    }
  },
  mounted() {
    this.defaultProfilePictureUrl = `${this.getBaseUrl()}/media/profiles/default.jpg`; // Set the default profile picture URL dynamically
    if (this.isAuthenticated) {
      this.loadProfilePicture(); // Load profile picture on mount if authenticated
    }
    this.detectMobile(); // Detect if the user is on a mobile device
    window.addEventListener('resize', this.detectMobile); // Add event listener for window resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.detectMobile); // Remove event listener when component is destroyed
  },
  watch: {
    $route() {
      // Re-evaluate authentication state when the route changes
      this.isAuthenticated = !!localStorage.getItem("authToken");
      if (this.isAuthenticated) {
        this.loadProfilePicture(); // Update profile picture on route change
      }
    }
  }
};
</script>

<style scoped>
/* General Layout */
#app {
  font-family: 'Helvetica', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full height for app */
}

/* Floating Search Bar Styling */
.floating-search {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  width: 80%;
  z-index: 1000;
}

.search-input {
  padding: 0.5rem;
  border-radius: 25px;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 100%;
  font-size: 1rem;
}

.search-btn {
  padding: 0.5rem 1rem;
  background-color: #b22222;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-btn:hover {
  background-color: #ff6666;
}

/* Search Icon Button Styling */
.search-icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #b22222;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}

.search-icon-btn:hover {
  color: #ff6666;
}

/* Navbar Styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #b22222;
  color: white;
  flex-shrink: 0;
}

.nav-left,
.nav-right,
.nav-search {
  display: flex;
  align-items: center;
}

.nav-left .nav-btn,
.nav-right .nav-btn {
  text-decoration: none;
  color: white;
  background-color: #b22222;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  margin-left: 15px;
  font-weight: 500;
  transition: background-color 0.3s, box-shadow 0.2s;
  cursor: pointer;
}

.nav-btn:hover {
  background-color: #ff6666;
}

.user-profile {
  margin-left: 15px;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid white;
}

.home-page-content {
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
}

/* Bottom Navbar for Mobile */
@media (max-width: 768px) {
  .navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-around;
    padding: 0.75rem 0;
    background-color: #b22222;
    z-index: 1000;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-left,
  .nav-right {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .nav-left .nav-btn,
  .nav-right .nav-btn {
    margin: 0;
  }

  .floating-search {
    top: 10px;
    width: 90%;
  }
}

/* Desktop Specific Styles */
@media (min-width: 769px) {
  .nav-search {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }

  .search-input {
    width: 250px;
  }
}
</style>

<style>
/* Global Styles */
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Prevent scrolling */
  box-sizing: border-box;
}
</style>